/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import DefaultHeadContent from 'components/DefaultHeadContent';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import HeadContent from 'components/HeadContent';
import ApplicationContext from './ApplicationContext';

export default function App({ context, styleContext, apolloClient, store, children }) {
  // NOTE: If you need to add or modify header, footer etc. of the app,
  // please do that inside the Layout component.
  return (
    <StyleContext.Provider value={styleContext}>
      <ApplicationContext.Provider value={context}>
        <ReduxProvider store={store}>
          <ApolloProvider client={apolloClient}>
            <DefaultHeadContent />
            <HeadContent
              contents={{
                robots: true,
                common: true,
              }}
            />
            {React.Children.only(children)}
          </ApolloProvider>
        </ReduxProvider>
      </ApplicationContext.Provider>
    </StyleContext.Provider>
  );
}

App.propTypes = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  styleContext: PropTypes.object.isRequired,
  apolloClient: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  context: PropTypes.shape({
    fetch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    query: PropTypes.object,
  }).isRequired,
  children: PropTypes.element.isRequired,
};
