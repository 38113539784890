/* eslint-disable react/no-danger */

// node modules
import React from 'react';
import PropTypes from 'prop-types';
import _style from 'understyle';

// load icons
const files = require.context('!svg-loader!./files', true, /\.svg$/);
const icons = {};

files.keys().forEach(fileName => {
  const icon = files(fileName);
  const name = fileName.replace('./', '').replace('.svg', '');

  icons[name] = icon;
});

class Icon extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    fill: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    fill: '#cccccc',
    className: '',
  };

  render() {
    const { fill, name, className, ...more } = this.props;
    const icon = icons[name];
    const style = {
      ..._style(this.props),
    };

    return (
      <svg
        fill={fill}
        className={className}
        style={style}
        viewBox={icon.attributes.viewBox}
        dangerouslySetInnerHTML={{ __html: icon.content }}
        {...more}
      />
    );
  }
}

export default Icon;
